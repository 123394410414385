'use strict';

$(document).ready(function () {
    $('.sliders__main').owlCarousel({
        items: 1,
        nav: true,
        loop: true,
        autoplay: true,
        autoplayTimeout: 6000,
        smartSpeed: 1500
    });

    $('.sliders__sale').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        smartSpeed: 1100
    });

    $('.open-menu').click(function () {
        $('.menu-catalog').css('display', 'block');
    });

    $('.menu-catalog__close').click(function () {
        $('.menu-catalog').css('display', 'none');
    });

    $('.catalogue__link-1').hover(function () {
        $('.catalogue__cylinder_image-1').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-1').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__link-2').hover(function () {
        $('.catalogue__cylinder_image-2').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-2').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__link-3').hover(function () {
        $('.catalogue__cylinder_image-3').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-3').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__cylinder_image-1').hover(function () {
        $('.catalogue__cylinder_image-1').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-1').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__cylinder_image-2').hover(function () {
        $('.catalogue__cylinder_image-2').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-2').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__cylinder_image-3').hover(function () {
        $('.catalogue__cylinder_image-3').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-3').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__cylinder-1 span').hover(function () {
        $('.catalogue__cylinder_image-1').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-1').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__cylinder-2 span').hover(function () {
        $('.catalogue__cylinder_image-2').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-2').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.catalogue__cylinder-3 span').hover(function () {
        $('.catalogue__cylinder_image-3').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.catalogue__cylinder_image-3').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__link-1').hover(function () {
        $('.gas__cylinder_image-1').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-small-hover.png');
        $('.text-1').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-1').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-small.png');
        $('.text-1').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__cylinder_image-1').hover(function () {
        $('.gas__cylinder_image-1').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-small-hover.png');
        $('.text-1').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-1').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-small.png');
        $('.text-1').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.text-1').hover(function () {
        $('.gas__cylinder_image-1').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-small-hover.png');
        $('.text-1').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-1').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-small.png');
        $('.text-1').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__link-2').hover(function () {
        $('.gas__cylinder_image-2').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-big-1.png');
        $('.gas__big').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-2').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-big.png');;
        $('.gas__big').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__cylinder_image-2').hover(function () {
        $('.gas__cylinder_image-2').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-big-1.png');
        $('.gas__big').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-2').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-big.png');;
        $('.gas__big').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__big').hover(function () {
        $('.gas__cylinder_image-2').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-big-1.png');
        $('.gas__big').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-2').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-big.png');;
        $('.gas__big').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__link-3').hover(function () {
        $('.gas__cylinder_image-3').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-middle-1.png');
        $('.text-3').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-3').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-middle.png');;
        $('.text-3').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__cylinder_image-3').hover(function () {
        $('.gas__cylinder_image-3').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-middle-1.png');
        $('.text-3').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-3').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-middle.png');;
        $('.text-3').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.text-3').hover(function () {
        $('.gas__cylinder_image-3').css({
            'transform': 'scale(1.05)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-middle-1.png');
        $('.text-3').css({
            'color': '#403434',
            'transition': 'all .6s ease-in-out'
        });
    }, function () {
        $('.gas__cylinder_image-3').css({
            'transform': 'scale(1)',
            'transition': 'all .6s ease-in-out'
        }).attr('src', './images/decor/gas-middle.png');;
        $('.text-3').css({
            'color': '#fff',
            'transition': 'all .6s ease-in-out'
        });
    });

    $('.gas__item-1').hover(function () {
        $('.gas__item_image-1').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-1').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__item-2').hover(function () {
        $('.gas__item_image-2').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-2').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__item-3').hover(function () {
        $('.gas__item_image-3').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-3').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__item-4').hover(function () {
        $('.gas__item_image-4').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-4').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__item-5').hover(function () {
        $('.gas__item_image-5').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-5').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__item-6').hover(function () {
        $('.gas__item_image-6').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-6').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__item-7').hover(function () {
        $('.gas__item_image-7').attr('src', './images/decor/catalogue-1.png');
    }, function () {
        $('.gas__item_image-7').attr('src', './images/decor/catalogue.png');
    });

    $('.gas__subtitle p').click(function () {
        $('.hidden').toggle('normal');
    });

    $(window).scroll(function () {
        $('.delivery__left').each(function () {
            var imagePos = $(this).offset().top;
            var topOfWindow = $(window).scrollTop();

            if (imagePos < topOfWindow + $(window).innerHeight()) {
                $(this).addClass('slide-in-active');
            } else {
                $(this).removeClass('slide-in-active');
            }
        });
    });

    $(window).scroll(function () {
        $('.delivery__right').each(function () {
            var imagePos = $(this).offset().top;
            var topOfWindow = $(window).scrollTop();

            if (imagePos < topOfWindow + $(window).innerHeight()) {
                $(this).addClass('slide-in-active');
            } else {
                $(this).removeClass('slide-in-active');
            }
        });
    });

    $(window).scroll(function () {
        $('.feedback__wrap').each(function () {
            var imagePos = $(this).offset().top;
            var topOfWindow = $(window).scrollTop();

            if (imagePos < topOfWindow + $(window).innerHeight()) {
                $(this).addClass('feedback__active');
            } else {
                $(this).removeClass('feedback__active');
            }
        });
    });

    $(window).scroll(function () {
        $('.inform__container').each(function () {
            var imagePos = $(this).offset().top;
            var topOfWindow = $(window).scrollTop();

            if (imagePos < topOfWindow + $(window).innerHeight()) {
                $(this).addClass('inform__active');
            } else {
                $(this).removeClass('inform__active');
            }
        });
    });

    function mouseMove() {
        $('.catalogue__wrapper').mouseover(function (e) {
            $('.catalogue__wrapper').mousemove(function (e) {
                var cox = (e.pageX - $(this).offset().left - $(this).width() / 2) / 20;

                $(this).find('.card').css('transform', 'rotateY(' + cox + 'deg)');
            });
        });
        $('.catalogue__wrapper').mouseleave(function (e) {
            $(this).find('.card').css('transform', 'rotateY(0)');
        });
    }

    mouseMove();

    if ($(window).width() < 698) {
        $('.menu-catalog__item').css('height', '100%');
    }
});